import React, { FC, useEffect, useState } from 'react';
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';
import classNames from 'classnames';

import { isBrowser } from 'utils/browser';
import { EMAIL, FACEBOOK, LINKEDIN, TWITTER, WHATSAPP } from 'utils/constants';

import Email from '../../../../common/icons/email.svg';
import Facebook from '../../../../common/icons/facebook.svg';
import LinkedIn from '../../../../common/icons/linkedin.svg';
import WhatsApp from '../../../../common/icons/whatsapp.svg';
import XTwitter from '../../../../common/icons/x-twitter.svg';
import { ArticleShareIconListProps } from './models';

import './ArticleShareIconList.scss';

const ArticleShareIconList: FC<ArticleShareIconListProps> = ({ isOpen, title }) => {
  const [pageUrl, setPageUrl] = useState<string>('');

  const pushGtmEvent = (eventName?: string) =>
    (window as any).dataLayer.push({
      event: `${eventName} share button click`,
      url: pageUrl,
    });

  useEffect(() => {
    if (isBrowser()) {
      setPageUrl(window.location.href);
    }
  }, []);

  return (
    <div
      className={classNames('article-banner__share-list-container', {
        'article-banner__share-list-container--open': isOpen,
      })}
    >
      <ul className="article-banner__share-list">
        <li className="article-banner__share-item">
          <LinkedinShareButton
            url={pageUrl}
            className="article-banner__share-btn"
            onClick={() => pushGtmEvent(LINKEDIN)}
          >
            <span aria-hidden="true" className="article-banner__share-icon">
              <LinkedIn />
            </span>
          </LinkedinShareButton>
        </li>
        <li className="article-banner__share-item">
          <TwitterShareButton
            url={pageUrl}
            className="article-banner__share-btn"
            onClick={() => pushGtmEvent(TWITTER)}
          >
            <span
              aria-hidden="true"
              className="article-banner__share-icon article-banner__share-icon--xtwitter"
            >
              <XTwitter />
            </span>
          </TwitterShareButton>
        </li>
        <li className="article-banner__share-item">
          <FacebookShareButton
            url={pageUrl}
            className="article-banner__share-btn"
            onClick={() => pushGtmEvent(FACEBOOK)}
          >
            <span aria-hidden="true" className="article-banner__share-icon">
              <Facebook />
            </span>
          </FacebookShareButton>
        </li>
        <li className="article-banner__share-item">
          <WhatsappShareButton
            url={pageUrl}
            className="article-banner__share-btn"
            onClick={() => pushGtmEvent(WHATSAPP)}
          >
            <span aria-hidden="true" className="article-banner__share-icon">
              <WhatsApp />
            </span>
          </WhatsappShareButton>
        </li>
        <li className="article-banner__share-item">
          <EmailShareButton
            url={pageUrl}
            className="article-banner__share-btn"
            subject={title}
            onClick={() => pushGtmEvent(EMAIL)}
          >
            <span aria-hidden="true" className="article-banner__share-icon">
              <Email />
            </span>
          </EmailShareButton>
        </li>
      </ul>
    </div>
  );
};

export default ArticleShareIconList;

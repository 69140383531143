import React, { FC, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import Arrow from '../../../../common/icons/dropdownArrow.svg';
import ArticleShareIconList from '../ArticleShareIconList';
import { ArticleShareDropdownProps } from './models';

import './ArticleShareDropdown.scss';

const ArticleShareDropdown: FC<ArticleShareDropdownProps> = ({ shareText, title, onDropdown }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleDropdown = () => {
    setIsOpen((prev) => !prev);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    onDropdown(isOpen);

    isOpen ? (document.body.style.overflow = 'hidden') : (document.body.style.overflow = 'unset');

    const checkIfClickedOutside = (e) => {
      if (isOpen && dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        onDropdown('');
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [isOpen]);

  return (
    <div
      className={classNames('article-banner__share-dropdown', {
        'article-banner__share-dropdown--open': isOpen,
      })}
      ref={dropdownRef}
    >
      <button
        type="button"
        className="article-banner__share-dropdown-btn"
        onClick={handleDropdown}
        aria-expanded={isOpen}
      >
        <span className="article-banner__share-dropdown-text">{shareText}</span>
        <span aria-hidden="true" className="article-banner__share-dropdown-icon">
          <Arrow />
        </span>
      </button>
      <ArticleShareIconList isOpen={isOpen} title={title} />
    </div>
  );
};

export default ArticleShareDropdown;

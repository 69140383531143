import React, { FC } from 'react';

import SimpleCtaBlocks from 'components/SimpleCtaBlocks';

import { ModulesContent } from '../../@types/content/modules';
import { ArticleCtaBlocksProps } from './models';

const ArticleCtaBlocks: FC<ArticleCtaBlocksProps> = ({ title, ctaLinks }) => {
  if (!ctaLinks || ctaLinks.length <= 0) return null;

  return (
    <div>
      <SimpleCtaBlocks
        module={{
          title,
          blocks: ctaLinks as ModulesContent.SimpleCtaBlockItemType[],
        }}
      />
    </div>
  );
};

export default ArticleCtaBlocks;

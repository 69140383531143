import React, { FC, ReactElement } from 'react';

import ColourfulQuoteSlider from 'components/ColourfulQuoteSlider';
import ContactUsInformation from 'components/ContactUsInformation';
import ContentBlock from 'components/ContentBlock';
import CtaCards from 'components/CtaCards';
import FullWidthVideo from 'components/FullWidthVideo';
import HeadingAndText from 'components/HeadingAndText';
import HealthierPlanetScroller from 'components/HealthierPlanetScroller';
import HeroQuote from 'components/HeroQuote';
import IFrame from 'components/IFrame';
import ImageAndVideoSlider from 'components/ImageAndVideoSlider';
import PlainAccordion from 'components/PlainAccordion';
import SingleCta from 'components/SingleCta';
import StatisticsWithImages from 'components/StatisticsWithImages';
import StrategyAccordion from 'components/StrategyAccordion';
import Table from 'components/Table';
import TitleAndDownload from 'components/TitleAndDownload';
import VideoAndImageWithText from 'components/VideoAndImageWithText';

import { ArticleBodyRendererProps } from './models';

const Components = {
  'Colourful Quote Slider': ColourfulQuoteSlider,
  'Content Block': ContentBlock,
  'Title And Download': TitleAndDownload,
  'Video And Image With Text': VideoAndImageWithText,
  'Heading And Text': HeadingAndText,
  Table,
  'Statistics With Images': StatisticsWithImages,
  'Single CTA': SingleCta,
  'Image And Video Slider': ImageAndVideoSlider,
  'Plain Accordion': PlainAccordion,
  'Strategy Accordion': StrategyAccordion,
  'CTA Cards': CtaCards,
  'Contact Us Information': ContactUsInformation,
  IFrame,
  'Healthier Planet Scroller': HealthierPlanetScroller,
  'Hero Quote': HeroQuote,
  'Full Width Video': FullWidthVideo,
};

const ArticleBodyRenderer: FC<ArticleBodyRendererProps> = ({ modules }): ReactElement => (
  <>
    {modules?.map((block, index) => {
      if (typeof Components[block.structure] === 'undefined') {
        return null;
      }

      const Component = Components[block.structure];

      return <Component key={`${block.structure}${index}`} module={block.module} />;
    })}
  </>
);

export default ArticleBodyRenderer;

import React, { FC } from 'react';

import ArticleShareIconList from '../ArticleShareIconList';
import { ArticleShareListProps } from './models';

import './ArticleShareList.scss';

const ArticleShareList: FC<ArticleShareListProps> = ({ shareText, title }) => (
  <div className="article-banner__share-container">
    <p className="article-banner__share-text">{shareText}</p>
    <ArticleShareIconList title={title} />
  </div>
);

export default ArticleShareList;

import React, { FC, useContext, useState } from 'react';
import classNames from 'classnames';

import ScreenRecognitionContext from '../../../contexts/screenRecognitionContext';
import ArticleShareDropdown from './ArticleShareDropdown';
import ArticleShareList from './ArticleShareList';
import { ArticleShareProps } from './models';

import './ArticleShare.scss';

const ArticleShare: FC<ArticleShareProps> = ({ shareText, title }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { isSmallMobile, isMobile } = useContext(ScreenRecognitionContext);

  const handleOnDropdown = (value) => {
    setIsOpen(value);
  };

  return (
    <>
      {isSmallMobile || isMobile ? (
        <div
          className={classNames('article-banner__share-mobile', {
            'article-banner__share-mobile--open': isOpen,
          })}
        >
          <ArticleShareDropdown
            shareText={shareText}
            title={title}
            onDropdown={(value) => handleOnDropdown(value)}
          />
        </div>
      ) : (
        <ArticleShareList shareText={shareText} title={title} />
      )}
    </>
  );
};

export default ArticleShare;

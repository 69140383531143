import React, { FC } from 'react';
import { graphql } from 'gatsby';

import { Col, Container, Row } from 'layout';
import Layout from 'layout/Layout';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';
import ArticleBanner from 'components/ArticleBanner';
import ArticleCtaBlocks from 'components/ArticleCtaBlocks';
import Breadcrumbs from 'components/Breadcrumbs';
import ImageAndVideoSlider from 'components/ImageAndVideoSlider';
import RelatedNews from 'components/RelatedNews';
import TitleAndDownloadItem from 'components/TitleAndDownload/TitleAndDownloadItem';

import ArticleBodyRenderer from './ArticleBodyRenderer';
import { ArticlePageTypes } from './models';

import 'styles/main.scss';
import './ArticlePage.scss';

const ArticlePage: FC<ArticlePageTypes.ArticlePageType> = ({
  data: { commonFields, articlePage },
}) => {
  const { seoMetaTitle, seoMetaDescription, seoMetaKeywords, seoExternalHreflangs } =
    articlePage.seo;
  const {
    level,
    title,
    creationDate,
    mainImageAndVideoSlider,
    previewText,
    articleTags,
    authors,
    readingTime,
    pressReleasePdf,
    ctaLinksTitle,
    ctaLinks,
    relatedNews,
    modules,
    lang,
    url,
  } = articlePage;
  const { articleShareText, articleReadingTimeText, articleWrittenByText } = commonFields;

  return (
    <Layout headerTransparent className="article-page">
      <Seo
        {...{
          seo: articlePage.seo,
          openGraph: articlePage.openGraph,
          pageTitle: title,
          seoExternalHreflangs,
          pageUrl: url,
          lang,
        }}
      />
      <PageSchema
        type="WebPage"
        name={seoMetaTitle}
        data={{
          metaTitle: seoMetaTitle,
          metaDescription: seoMetaDescription,
          metaKeywords: seoMetaKeywords,
        }}
      />

      <div className="banners">
        <Breadcrumbs level={level} />
        <ArticleBanner
          title={title}
          creationDate={creationDate}
          previewText={previewText}
          articleTags={articleTags}
          readingTimeText={articleReadingTimeText}
          writtenByText={articleWrittenByText}
          authors={authors}
          readingTime={readingTime}
          shareViaText={articleShareText}
          lang={lang}
        />
      </div>

      <div>
        {mainImageAndVideoSlider ? <ImageAndVideoSlider module={mainImageAndVideoSlider} /> : null}
      </div>

      <div id="modules">
        {pressReleasePdf ? (
          <div className="title-and-download section">
            <Container>
              <Row className="row--center">
                <Col xl="8" className="col--no-gutters">
                  <TitleAndDownloadItem
                    title={pressReleasePdf.title}
                    ctaLink={pressReleasePdf.ctaLink}
                  />
                </Col>
              </Row>
            </Container>
          </div>
        ) : null}
        <ArticleBodyRenderer modules={modules} />
        {ctaLinks && ctaLinks.length ? (
          <ArticleCtaBlocks title={ctaLinksTitle} ctaLinks={ctaLinks} />
        ) : null}
        {relatedNews ? <RelatedNews module={relatedNews} /> : null}
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ($url: String) {
    commonFields {
      articleShareText
      articleReadingTimeText
      articleWrittenByText
    }
    articlePage(url: { eq: $url }) {
      pageId
      level
      lang
      url
      previewLinkTitle
      seo {
        ...SEOStructureFragment
      }
      openGraph {
        ...OpenGraphFragment
      }
      title
      creationDate
      mainImageAndVideoSlider {
        ...FragmentImageAndVideoSlider
      }
      previewText
      articleTags {
        name
        children {
          name
          tagName
        }
      }
      authors {
        name
        profilePhoto {
          ...FragmentImage
        }
      }
      readingTime
      pressReleasePdf {
        title
        ctaLink {
          ...FragmentLink
        }
      }
      ctaLinksTitle
      ctaLinks {
        text
        ctaLink {
          ...FragmentLink
        }
      }
      relatedNews {
        ...FragmentRelatedNews
      }
      modules {
        ...FragmentArticleModules
      }
    }
  }
`;

export default ArticlePage;

import React, { FC } from 'react';

import { Col, Container, Row } from 'layout';
import NewsGridAuthors from 'common/NewsGrid/NewsGridAuthors';
import NewsGridTags from 'common/NewsGrid/NewsGridTags';
import { formatDisplayDate } from 'utils/dateHandler';

import ArticleShare from './ArticleShare';
import { ArticleBannerProps } from './models';

import './ArticleBanner.scss';

const ArticleBanner: FC<ArticleBannerProps> = ({
  title,
  creationDate,
  previewText,
  articleTags,
  readingTimeText,
  writtenByText,
  authors,
  readingTime,
  shareViaText,
  lang,
}) => (
  <div className="article-banner">
    <Container>
      <ArticleShare shareText={shareViaText} title={title} />
    </Container>
    <div className="article-banner__container">
      <Container>
        <Row>
          <Col xl="8" className="col--no-gutters">
            <h1 className="article-banner__title h2">{title}</h1>
            <p className="article-banner__text standfirst">{previewText}</p>
            <NewsGridTags article={articleTags} />
            {authors && authors.length ? (
              <NewsGridAuthors authors={authors} writtenByText={writtenByText} />
            ) : null}
            <div className="article-banner__date-and-reading-time">
              <span className="article-banner__time tag">
                {formatDisplayDate(creationDate, lang)}
              </span>
              {readingTime < 1 ? null : (
                <span className="article-banner__reading-time tag">{`${readingTime} ${readingTimeText}`}</span>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  </div>
);

export default ArticleBanner;
